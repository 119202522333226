/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Mobile logo positioning */
@media (max-width: 768px) {
  .App-logo, .MuiToolbar-root img {
    position: absolute !important;
    left: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    transform: none !important;
  }
  
  .MuiToolbar-root {
    padding: 0 !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  .MuiToolbar-gutters {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Override any potential Box padding */
  .MuiBox-root {
    padding-left: 0 !important;
  }

  /* Override any container padding */
  .MuiContainer-root {
    padding-left: 0 !important;
  }

  /* Override any AppBar padding */
  .MuiAppBar-root {
    padding: 0 !important;
  }

  /* Reset text alignment */
  .App {
    text-align: left !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: #931d1d; /* Light grey background */
}
.App-header {
  background-color: #5d4280; /* updated to the shade of purple */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: background-color 0.3s;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px #aaa;
  }
  to {
    box-shadow: 0 0 20px #ffa;
  }
}

.glow {
  animation: glow 2s infinite alternate;
}

/* Ensure AppBar is sticky */
.MuiAppBar-root {
  position: sticky;
  top: 0;
  z-index: 1100; /* Ensure it's above other items */
}

.App-link {
  color: #ffe09d; /* updated to the pale, yellow-orange */
}

/* Add this to your CSS file or within a <style> tag in your component */
.modal-image-lightbox {
  max-width: 50%; /* Adjust the width as needed */
  max-height: 50%; /* Adjust the height as needed */
}

.modal-image-img {
  width: 100%;
  height: auto;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns of equal width */
  grid-template-rows: auto; /* Height of rows set to auto to adjust to content */
  grid-gap: 10px; /* Adjust the gap as needed */
}

.header-image {
  grid-column: 1 / -1; /* Span across all four columns */
  height: 100%; /* Take full height of the row */
  object-fit: cover; /* To make sure image covers the area without stretching */
}

.gallery-image {
  height: 100%; /* Take full height of the row */
  object-fit: cover; /* To make sure image covers the area without stretching */
}

.footer-background {
  position: relative;
  min-height: 850px;
  padding-bottom: 2rem;
}

.footer-red, .footer-blue {
  position: absolute;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-red {
  background-image: url('/public/RedFooter.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  top: 0;
}

.footer-blue {
  background-image: url('/public/BlueFooter.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  bottom: 0;
}

/* New styles for AI Makers bar */
.ai-makers-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Lighter opacity */
  color: white;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.8rem;
}

.ai-makers-bar a {
  color: white;
  text-decoration: none; /* Remove underline */
}

.ai-makers-bar a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.admin-button:hover {
  background-color: #1a237e;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}